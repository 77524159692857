import React from 'react';
import { Container, Accordion, Card, Button } from 'react-bootstrap';

const FAQSection = () => {
  const faqData = [
    { question: 'How do I book a cab?', answer: 'You can easily book a cab through our website. Simply fill the contact form and submit you contact details and we will contact you, and complete the booking process.' },
    { question: 'What areas do you serve?', answer: 'We provide cab services throughout India, starting from Uttarakhand (UK). Whether it\'s a local commute or an interstate journey, we\'ve got you covered.' },
    { question: 'Are your prices fixed?', answer: 'Yes, our pricing is transparent and competitive. You\'ll know the exact fare before confirming your booking. We believe in providing affordable and reliable transportation solutions.' },
  ];

  return (
    <section className="faq-section text-light py-5" style={{backgroundColor:'rgba(241, 241, 241, 1)',backgroundImage:'linear-gradient(to top,#f1f1f1,#dee6f9)'}}>
      <Container>
        <h2 style={{ color: '#FFC107' }} className='text-center py-4'>Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={['0']} alwaysOpen >
          {faqData.map((faq, index) => (
            <FAQAccordion key={index} index={index} {...faq} />
          ))}
        </Accordion>
      </Container>
    </section>
  );
};

const FAQAccordion = ({ index, question, answer }) => {
  return (
     <Accordion.Item  eventKey={index} className='m-1 '>
        <Accordion.Header  className='fs-5'>
          {question}
        </Accordion.Header>
      
      <Accordion.Body className='fs-6'>
        {answer}
      </Accordion.Body>
      </Accordion.Item>
   
  );
};

export default FAQSection;
