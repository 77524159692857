import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Footer() {
  return (
    <footer className="bg-dark text-light py-4 ">
        <Container>
          <Row>
            <Col>
            <h5>Explore Cab Services in Nainital</h5>
             
                <ul>
                  <li>
                    <strong>City Tours in Nainital:</strong> Discover the beauty
                    of Nainital with both full-day and half-day city tours.
                  </li>
                  <li>
                    <strong>Airport Transfers:</strong> Avail reliable domestic
                    and international airport transfers from Nainital's railway
                    station.
                  </li>
                  <li>
                    <strong>Bus Station Pick-up and Drop-off:</strong> Convenient
                    services for pick-up and drop-off at the bus station in
                    Nainital.
                  </li>
                  <li>
                    <strong>Outstation Vehicle Rental with Driver:</strong> Plan
                    trips beyond Nainital with outstation vehicle rentals,
                    accompanied by experienced drivers.
                  </li>
                  <li>
                    <strong>Wedding Ceremony Cabs:</strong> Specialized cabs
                    available for wedding ceremonies in Nainital.
                  </li>
                  <li>
                    <strong>Discounted Taxi Services:</strong> Enjoy the best
                    discounts on commercial taxis for travel from Nainital to
                    nearby cities.
                  </li>
                </ul>
             <p>Discover a diverse range of cars with leading cab operators, all equipped with modern amenities and top-notch safety features such as GPS. Our well-maintained fleet, coupled with trained chauffeurs, ensures your journeys are not only safe but also comfortable. Benefit from competitive prices for a premium travel experience.</p>

            </Col>
          </Row>
          <Row className='mt-4'>
            <Col >
              <h5>Contact Us</h5>
              {/* <p>Email: info@travelagency.com</p> */}
              <p>Phone: +919759020267</p>
              <p>Nainital Car Booking
Nariman Chauraha, Bareilly - Nainital Rd, Kathgodam near railway station Kathgodam</p>
            </Col>
            <Col >
              <h5>Links</h5>
              {/* Add your social media icons/links here */}
              <p>Blog | About Us | Contact Us</p>
             
            </Col>
            <Col >
              <h5>Follow Us</h5>
              {/* Add your social media icons/links here */}
              <p>Facebook | Twitter | Instagram</p>
            </Col>
          </Row>
          <Row className="my-5">
            <Col>
              <p className="text-center">&copy; {new Date().getFullYear()}Nainital Car Booking. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
  )
}

export default Footer