import { Card, Col, Container, Row } from 'react-bootstrap';
import { FaHeadset, FaGlobe, FaMoneyBillWave, FaShieldAlt } from 'react-icons/fa';


const servicesData = [
  {
    title: "24/7 Customer Support",
    description: "Our dedicated support team is ready to assist you anytime, anywhere.",
    icon: <FaHeadset size={30} color='#FFC107' />,
  },
  {
    title: "All India Travel from Kathgodam/Nainital ",
    description: "Explore India with our cab services starting from Kathgodam/Nainital(Uttarakhand). Reach any destination hassle-free.",
    icon: <FaGlobe size={30} color='#FFC107'/>,
  },
  {
    title: "Reasonable Prices",
    description: "Enjoy the best-in-class travel experience at affordable and transparent prices.",
    icon: <FaMoneyBillWave size={30} color='#FFC107'/>,
  },
  {
    title: "Clean and Well-Maintained Fleet",
    description: "Travel in comfort and style with our fleet of clean and well-maintained vehicles. Your safety and satisfaction are our top priorities.",
    icon: <FaShieldAlt size={30} color='#FFC107'/>,
  },
];

const ServicesSection = () => {
  return (
    <section className='py-5' style={{backgroundImage:'url(https://boston-george.s3.amazonaws.com/uploads/1706336505380-nainital-bg.jpg)',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%'}}>
      <Container className='py-5'>
        <h2 style={{ color: '#FFC107' }} className='text-center  py-4'>Our Services</h2>
        <Row>
          {servicesData.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

const ServiceCard = ({ title, description, icon }) => {
  return (
    <Col  md={3} className='p-1'>
      <Card className="service-card bordered rounded shadow  p-3 bg-dark text-white" style={{height:'100%'}}>
        <div style={{height:'30%'}}>
        <div className="service-icon text-center">{icon}</div>
        <h5 className='text-center '>{title}</h5>
        </div>
        <hr/>
        
        <p className=''>{description}</p>
      </Card>
    </Col>
  );
};


export default ServicesSection;