import axios from "axios"



const BASE_URL = 'https://api.engineeringprojectskart.com/api/admin'

const api = axios.create({
    baseURL:BASE_URL,
});


export default api;