import { Col, Container, Image, Row } from "react-bootstrap";

const HowToBook = () => {
    return (
      <section className="how-to-book-section py-5" style={{backgroundColor:'rgba(241, 241, 241, 1)',backgroundImage:'linear-gradient(to top,#f1f1f1,#dee6f9)'}}>
        <Container>
          <h2 style={{ color: '#FFC107' }} className="text-center">How To Book</h2>
          <Row>
          <Col md={7} className=" p-2 ">
          <p><span className="fw-bold">1. Call Us Directly:</span><br/>
Dial our dedicated booking hotline at 9759020267 to connect with our customer service experts. Whether you're unsure about the best vehicle for your needs or have specific preferences in mind, our friendly team is ready to guide you through the process. Booking a ride has never been so effortless—just a phone call away!
</p>
<p>
<span className="fw-bold">2. WhatsApp Booking:</span> <br/>
Experience the ease of instant messaging with our WhatsApp booking option. Click on 'Chat on Whatsapp' button to initiate a chat with our responsive team. From inquiries to reservations, we're here to assist you every step of the way. Chat with us on the go and receive prompt confirmation and details for your upcoming ride.
</p>
<p>
    
<span className="fw-bold">3. Online Booking Form:</span> <br/>
Prefer a digital approach? Fill out our straightforward online booking form below for a quick and efficient reservation process.
  </p>  
            </Col>
            
            <Col >
                <Image src= "https://boston-george.s3.amazonaws.com/uploads/1706335391495-etios.png" fluid />
            </Col>
            
          </Row>
        </Container>
      </section>
    );
  };
  
  export default HowToBook;