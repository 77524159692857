import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import Slider from 'react-slick';

function PlacesCovered() {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 4, 
        slidesToScroll: 1,
        adaptiveHeight: true,
        centerMode: true,
        responsive: [
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
      };

      const destinations = [
        {
          imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706336200106-bhimtal.png",
          title: 'Bhimtal',
        //   description: 'Explore the rich history and culture of India\'s capital city, Delhi. Visit iconic landmarks such as the Red Fort, India Gate, and Qutub Minar.',
        },
        {
          imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706336200106-bhowali.png",
          title: 'Bhowali',
        //   description: 'Experience spiritual tranquility in the holy city of Haridwar. Attend the mesmerizing Ganga Aarti on the banks of the sacred Ganges River.',
        },
        {
          imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706336269427-hanumangarh-temple.png",
          title: 'Hanumangarh Temple',
        //   description: 'Escape to the scenic beauty of Auli, a winter wonderland known for its snow-covered landscapes and excellent skiing opportunities.',
        },
        {
          imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706336269427-sattal.png",
          title: 'Sattal',
        //   description: 'Embark on a pilgrimage to the sacred Char Dham, including Yamunotri, Gangotri, Kedarnath, and Badrinath, nestled in the Himalayas.',
        },
        {
            imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706336269427-naukuchiatal.png",
            title: 'Naukuchiatal',
            // description: 'Visit the charming hill station of Nainital, known for its pristine lakes, lush greenery, and breathtaking views of the Himalayas.',
        },
        {
            imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706336269427-nal-damyanti-tal.png",
            title: 'Nal Damyanti Tal',
            // description: 'Visit the charming hill station of Nainital, known for its pristine lakes, lush greenery, and breathtaking views of the Himalayas.',
        },
        {
            imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706336155147-kamal-tal-lake.png" ,
            title: 'Kamal Tal Lake',
            // description: 'Visit the charming hill station of Nainital, known for its pristine lakes, lush greenery, and breathtaking views of the Himalayas.',
        },
      ];
      

  return (
    <section className="destinations-section py-5" style={{backgroundColor:'rgba(241, 241, 241, 1)',backgroundImage:'linear-gradient(to top,#f1f1f1,#dee6f9)'}}>
      <Container>
      <h2 style={{ color: '#FFC107' }} className='text-center py-4'> Most Covered Destinations</h2>
       {/* <h5>Starting from Kathgodam/Nainital (UK)</h5> */}
        <Slider {...settings} >
          {destinations.map((destination, index) => (
            <CoveredCard
              key={index}
              imageSrc={destination.imageSrc}
              title={destination.title}
              description={destination.description}
            />
          ))}
       </Slider>
      </Container>
    </section>
  )
}


function CoveredCard({ imageSrc, title, description }) {
    return (
      
        <Card className="destination-card rounded-4 m-2 shadow" style={{backgroundImage:`url(${imageSrc})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',height:'200px'}}>
            {/* <Card.Body> */}
            {/* <Image src={imageSrc} alt={title} fluid /> */}
            {/* </Card.Body> */}
          <Card.Footer style={{position:'absolute',bottom:'0'}}>
          
          
         
          <h5 className='p-1 m-0' style={{backgroundColor:'#FFC107'}}>{title}</h5>
          {/* <p>{description}</p> */}
        
          </Card.Footer>
        </Card>
      
    );
  }

export default PlacesCovered