import React, { useState } from "react";
import { Container, Form, Button, Col, Row, Image, Spinner } from "react-bootstrap";
import api from "../utils/axios";
import toast from "react-hot-toast";

const BookingForm = () => {
  const [formData, setFormData] = useState({
    name:'',
    email:'',
    phone:'',
    message:''

  });
  const [loading,setLoading]= useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async(e)=>{
    e.preventDefault();

    try {
      setLoading(true);
      console.log(formData);
      const response = await api.post('/email',formData);
      setLoading(false);
      console.log(response);
      setFormData({
        name:'',
        email:'',
        phone:'',
        message:''
    
      });
      toast.success('Form Submitted');
      
    } catch (error) {
      setLoading(false)
      console.log(error);
      toast.error('Error submitting form, please try again!')
    }
  }

  return (
    <section className="bg-dark" id="bookingFormSection">
      <Container>
        <h2 style={{ color: "#FFC107" }} className="text-center py-4">
          Book Your Journey
        </h2>
        <Row>
          <Col md={6}>

            <Form
              className="text-white"
              onSubmit={handleSubmit}
            >

              <Form.Group controlId="formName">
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Your Name "
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="email@example.com"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="0000000000"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Label>Additional Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  placeholder="Specify your destination"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Button variant="warning" type="submit" className="my-3">
                {loading?<Spinner variant="border"/> :'Submit Booking'}

             
              </Button>
            </Form>
          </Col>

          <Col className="d-flex align-items-end">
            <Image
              src="https://boston-george.s3.amazonaws.com/uploads/1706335391495-innova.png"

              fluid
              style={{ maxHeight: "300px" }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BookingForm;
