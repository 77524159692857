import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';

function PopularDestinations() {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 3, 
        slidesToScroll: 1,
        adaptiveHeight: true,
        centerMode: true,
        responsive: [
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
      };

      const destinations = [
        {
          imageSrc:  "https://boston-george.s3.amazonaws.com/uploads/1706335871095-delhi.jpg",
          title: 'Delhi',
          description: 'Explore the rich history and culture of India\'s capital city, Delhi. Visit iconic landmarks such as the Red Fort, India Gate, and Qutub Minar.',
        },
        {
          imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706335871095-haridwar.jpg",
          title: 'Haridwar',
          description: 'Experience spiritual tranquility in the holy city of Haridwar. Attend the mesmerizing Ganga Aarti on the banks of the sacred Ganges River.',
        },
        {
          imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706335871094-auli.webp",
          title: 'Auli',
          description: 'Escape to the scenic beauty of Auli, a winter wonderland known for its snow-covered landscapes and excellent skiing opportunities.',
        },
        {
          imageSrc: "https://boston-george.s3.amazonaws.com/uploads/1706335871095-chardham.jpg",
          title: 'Chardham',
          description: 'Embark on a pilgrimage to the sacred Char Dham, including Yamunotri, Gangotri, Kedarnath, and Badrinath, nestled in the Himalayas.',
        },
        {
            imageSrc:   "https://boston-george.s3.amazonaws.com/uploads/1706335150072-uk2.jpg",
            title: 'Nainital',
            description: 'Visit the charming hill station of Nainital, known for its pristine lakes, lush greenery, and breathtaking views of the Himalayas.',
          },
      ];
      

  return (
    <section className="destinations-section py-5" style={{backgroundColor:'rgba(241, 241, 241, 1)',backgroundImage:'linear-gradient(to top,#f1f1f1,#dee6f9)'}}>
      <Container>
      <h2 style={{ color: '#FFC107' }} className='text-center py-4'>Featured Destinations</h2>
       <h5>Starting from Kathgodam/Nainital (UK)</h5>
        <Slider {...settings} >
          {destinations.map((destination, index) => (
            <FeaturedCard
              key={index}
              imageSrc={destination.imageSrc}
              title={destination.title}
              description={destination.description}
            />
          ))}
       </Slider>
      </Container>
    </section>
  )
}


function FeaturedCard({ imageSrc, title, description }) {
    return (
      
        <Card className="destination-card rounded-4 m-2 shadow" style={{minHeight:'350px'}}>
          <img src={imageSrc} alt={title} className="img-fluid" style={{height:'200px'}}/>
        <div className='p-3'
        //  style={{height:'50px'}}
         >
          <h4>{title}</h4>
          <p>{description}</p>
          </div>  
        </Card>
      
    );
  }

export default PopularDestinations