import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link, scroller } from 'react-scroll';
import { FaWhatsapp } from 'react-icons/fa';
import api from '../utils/axios';
import toast from 'react-hot-toast';

function HeroSection() {
  const carouselImages = [ "https://boston-george.s3.amazonaws.com/uploads/1706335150072-aulibg.jpg", 
  "https://boston-george.s3.amazonaws.com/uploads/1706335150072-uk2.jpg",
   "https://boston-george.s3.amazonaws.com/uploads/1706335150072-uk3.jpg"];

  const [isFixed, setIsFixed] = useState(false);


  useEffect(() => {
     
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsFixed(offset > 50); 
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  
}, []);

  const handleWhatsappClick = () => {
    handleButtonClick();
    const phoneNumber = '+919759020267';
    const url = `https://api.whatsapp.com/send/?phone=${encodeURIComponent(phoneNumber)}&text&type=phone_number&app_absent=0`;
    window.open(url, '_blank');
  };

  const handleCallButtonClick = () => {
    handleButtonClick();
    const canMakeCall = !!navigator.userAgent.match(/iPhone|iPad|iPod|Android/i);

    if (canMakeCall) {
      window.location.href = 'tel:+919759020267';
    } else {
    //   navigate("/contact-us");
    const bookingSectionId = 'bookingFormSection'; 
    const offset = -100; 

    scroller.scrollTo(bookingSectionId, {
        smooth: true,
        duration: 500,
        offset,
      });
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    cssEase: 'linear',
  };


  const handleButtonClick = async()=>{
    try {
       const response = await api.post('/phone');
       console.log(response);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong')
    }
  }

  return (
    <section className='position-relative main-section' >
      <Slider {...settings}>
        {carouselImages.map((image, index) => (
          <div key={index} className='main-section' style={{  overflow: 'hidden' }}>
            <Image
              src={image}
              alt={`Carousel ${index}`}
              fluid
              className='main-section'
              style={{ width: '100vw' }}
              
            />
          </div>
        ))}
      </Slider>

      <Container className='position-absolute top-50 start-50 translate-middle text-center'>
        <Row className='d-flex justify-content-center'>
          <Col md={10}>
            <Card style={{background:'rgba(0,0,0,0.1)',backdropFilter:'blur(2px)'}} className='p-2'>
            <h1 className='display-2 fw-bold' style={{ color: '#FFC107' }}>
              {/* Discover Seamless Car Travel */}
              Nainital Car Booking
            </h1>
            <p className='text-white'>
              Your trusted partner for comfortable and reliable journeys across India.
            </p>
            </Card>
            <Link
              to="bookingFormSection" 
              smooth={true}
              duration={500}
              offset={-100}
            >
            <Button variant='success' size='lg' className='my-2' >
              Book Now
            </Button>
            </Link>
           
          </Col>
        </Row>

        
      </Container>
      <Row 
            // className={isMobileOrTablet ? (isFixed ? 'fixed-bottom px-4' : '') : 'p-2'}
            // className={(isFixed ? 'fixed-bottom px-4' : '') }
            className={'fixed-bottom px-4'}
            >
            
              <Col md={6}>
              <Button
               onClick={handleCallButtonClick}
                variant="dark"
                className="border-white fw-bold border-1 rounded-pill w-100 text-white text-nowrap  m-1 "
                
              >
                Call to Book
              </Button>
              </Col>

              <Col >
            <Button
                onClick={handleWhatsappClick}
                variant="success"
                className=" border-white border-1 fw-bold rounded-pill w-100 text-nowrap px-4 m-1  speak-to-exp-btn "
              >
                <FaWhatsapp size={19}/> Chat on WhatsApp
              </Button>
            </Col>
            </Row>
    </section>
  );
}

export default HeroSection;
