import { Col, Container, Image, Row } from "react-bootstrap";

const AboutUs = () => {
    return (
      <section className="about-us-section py-5">
        <Container>
          <h2 style={{ color: '#FFC107' }} className="text-center">About Us</h2>
          <Row>
            <Col >
                <Image src="https://boston-george.s3.amazonaws.com/uploads/1706335391495-swift.png" fluid />
            </Col>
            <Col md={8} className=" p-2 ">
            <p>
              Welcome to Nainital Car Booking, your trusted companion for unparalleled cab services with a legacy of excellence. With over 10 years of experience in the industry, we have been dedicated to providing seamless and reliable transportation solutions to our valued customers.
            </p>
            <p>
              Operating from the beautiful state of Uttarakhand (UK), we extend our services across the length and breadth of India. Whether you are planning a local journey or embarking on a cross-country adventure, our fleet of well-maintained vehicles and professional drivers ensure a comfortable and enjoyable ride.
            </p>
            <p>
              Our commitment to customer satisfaction is at the heart of everything we do. We prioritize safety, punctuality, and affordability, making us the preferred choice for individuals and businesses alike. Experience the convenience of hassle-free bookings, transparent pricing, and 24/7 customer support.
            </p>
            </Col>
            
          </Row>
        </Container>
      </section>
    );
  };
  
  export default AboutUs;