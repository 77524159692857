import React from 'react'
import Services from '../components/Services'
import HeroSection from '../components/HeroSection'
import BookingForm from '../components/BookingForm'
import TestimonialsSection from '../components/TestimonialsSection'
import AboutUs from '../components/Aboutus'
import FAQSection from '../components/FAQ'
import GallerySection from '../components/Gallery'
import PopularDestinations from '../components/PopularDestinations'
import HowToBook from '../components/HowToBook'
import PlacesCovered from '../components/PlacesCovered'

function Home() {
  return (
    <>
        <HeroSection/>
        <AboutUs/>
        <HowToBook/>
        <Services />
        <PopularDestinations/>
        <BookingForm/>
        <TestimonialsSection/>
        <GallerySection/>
        <FAQSection/>
        <PlacesCovered/>
    </>
  )
}

export default Home