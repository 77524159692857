import React from 'react'
import { Navbar, Nav, NavDropdown, Button, Container } from 'react-bootstrap';
import { scroller } from 'react-scroll';

function Header() {

  const handleClick= ()=>{
    const bookingSectionId = 'bookingFormSection'; 
    const offset = -100; 

    scroller.scrollTo(bookingSectionId, {
        smooth: true,
        duration: 500,
        offset,
      });
    }
  

  return (
<>
<Navbar className="bg-dark"  sticky="top"
 style={{ height: "50px"}}
 
 >
  <Container>
    <Navbar.Brand
      className="fw-bold fs-3  text-white"
      style={{ cursor: "pointer" }}
    >
     Nainital Car Booking
    </Navbar.Brand>
    <Nav className="  ">
      <Nav.Link onClick={handleClick} className=" px-md-3 fw-bold text-white bg-success rounded-pill text-nowrap">Book Now</Nav.Link>
      {/* <Nav.Link className=" fs-5 mx-5 text-white">Features</Nav.Link>
      <NavDropdown title="Destinations" id="collapsible-nav-dropdown" className=' fs-5 text-white'>
        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown> */}
    </Nav>

    {/* <Button
      variant="outline-light"
      className="rounded-pill px-4 fw-bold"
    >
      Login
    </Button> */}
  </Container>
</Navbar>

<div style={{backgroundColor:'#B5651D',position:"fixed",zIndex:'100',width:'100vw',}} className="alert-header text-white text-center fw-bold ">"Prices are at 20% discounted than the market price" <div className='get-quote-link ' style={{display:'inline-block'}}><p onClick={handleClick} className=' p-1 mb-0' style={{color:'#00FF00',cursor:'pointer'}}> Book Now</p></div> </div>

  </>
  );
};


export default Header