import { Card, Col, Container, Row } from "react-bootstrap";

const TestimonialsSection = () => {
  const testimonialsData = [
    { name: 'Rahul Sharma', feedback: 'Excellent service! The drivers were punctual, and the cars were clean and comfortable.' },
    { name: 'Deepak Mishra', feedback: 'I highly recommend "Uk travels". They made my journey smooth and enjoyable.' },
    { name: 'Pradyumna Charate', feedback: 'Reasonable prices and great customer support. Will use their services again.' },
    { name: 'Priya Singh', feedback: 'Outstanding experience! The attention to detail and professionalism of the staff exceeded my expectations. Definitely my go-to travel service.' },
  ];
  
    return (
      <section className="testimonials-section py-5" 
      // style={{backgroundColor:'rgba(241, 241, 241, 1)',backgroundImage:'linear-gradient(to top,#f1f1f1,#dee6f9)'}}
      style={{backgroundImage:'url(https://boston-george.s3.amazonaws.com/uploads/1706336505380-nainital.jpg)',backgroundSize:'100% 100%',backgroundRepeat:'no-repeat'}}
      >
        <Container className="py-5">
          <h2 style={{ color: '#FFC107' }} className="text-center my-4">What Our Customers Say</h2>
          <Row className="my-5">
            {testimonialsData.map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </Row>
        </Container>
      </section>
    );
  };
  
  const TestimonialCard = ({ name, feedback }) => {
    return (
      <Col md={3} className="p-2">
        <Card className="testimonial-card border-0 p-3 border  text-white shadow h-100"
        style={{background:'rgba(0,0,0,0.2)',backdropFilter:'blur(2px)'}}
        >
          <p className="mb-5">{feedback}</p>
          <p className="customer-name text-center position-absolute  bottom-0" style={{ color: '#FFC107' }}>{`- ${name}`}</p>
        </Card>
      </Col>
    );
  };
  
  export default TestimonialsSection