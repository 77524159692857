import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import { Toaster } from 'react-hot-toast';


function App() {
  return (
    <>
    <Toaster
  position="top-center"
  reverseOrder={false}
/>
  <Header/>
    <Routes>
      
      <Route path='/' element={<Home/>} />
    
    </Routes>
    <Footer/>
   </>
  );
}

export default App;
