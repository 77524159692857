import React from 'react';
import { Container, Image } from 'react-bootstrap';
import Slider from 'react-slick';


const GallerySection = () => {
  const carouselImages = [
    "https://boston-george.s3.amazonaws.com/uploads/1706336387463-img1.jpeg",
    "https://boston-george.s3.amazonaws.com/uploads/1706336387463-img2.jpeg",
    "https://boston-george.s3.amazonaws.com/uploads/1706336387463-img3.jpeg",
    "https://boston-george.s3.amazonaws.com/uploads/1706336387463-img4.jpeg"
  
  ];

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 3, // Adjust the number of slides to show
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
  };

  return (
    <section className="gallery-section bg-dark text-light py-4">
      <Container>
        <h2 style={{ color: '#FFC107' }} className='text-center py-4'>Explore Our Gallery</h2>
        <Slider {...settings} >
          {carouselImages.map((image, index) => (
            <div key={index} className='p-2'>
              <Image
                className="d-block w-100"
                src={image}
                alt={`Gallery ${image}`}
                style={{maxHeight:'300px'}}
              />
            </div>
          ))}
        </Slider>
      </Container>
    </section>
  );
};

export default GallerySection;